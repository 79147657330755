import React from 'react';
import { DataCenterMetadataProps } from '../../lib/sanity-types/data-center';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';

const PricingCard = (props: DataCenterMetadataProps) => {
  return (
    <div className='my-2 px-2 w-full sm:my-2 sm:px-2 sm:w-full md:my-2 md:px-2 md:w-1/2 lg:my-2 lg:px-2 lg:w-1/2 xl:my-2 xl:px-2 xl:w-1/3'>
      <motion.div
        whileHover={{
          y: 15,
          transition: { ease: 'easeInOut', duration: 0.4 },
        }}
        className='flex flex-col py-3 px-6 h-full w-full border rounded'>
        <svg
          className='text-blue-aqua w-8 h-8 ml-auto mb-2'
          fill='none'
          stroke='currentColor'
          viewBox='0 0 24 24'
          xmlns='http://www.w3.org/2000/svg'>
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={2}
            d='M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z'
          />
        </svg>

        <div className='flex flex-col items-center'>
          <h2 className='font-universBoldCondensed text-titleSmallMobile sm:text-titleSmallTablet lg:text-titleSmall mb-1'>
            {props.name}
          </h2>
          <div className='font-universBoldCondensed sm:text-lg lg:text-xl mb-8 text-center'>
            {props.location}
          </div>
          <div className='flex flex-row items-center mb-3'>
            <div className='sm:text-lg lg:text-xl self-start'>{props.currency}</div>
            <div className='font-universBoldCondensed text-titleSmallMobile sm:text-titleSmallTablet lg:text-titleSmall mx-1'>
              {props.price}
            </div>
            <div className='sm:text-lg lg:text-xl self-end'>KwH</div>
          </div>
          <p className='mb-8 leading-relaxed break-words'>{props.description}</p>
        </div>
        <h3 className='mb-3 font-universBoldCondensed'>Beschreibung</h3>
        <ul className='mb-8 list-inside list-disc'>
          {props.properties.map((property: string, index: number) => {
            return (
              <li key={index} className='mb-1'>
                {property}
              </li>
            );
          })}
        </ul>
        <div className='mt-auto'>
          <Link
            to={props.route}
            className='inline-block overflow-hidden w-full py-3 px-6 leading-none font-universBoldCondensed text-center rounded shadow focus:shadow-outline transition-colors duration-150 text-white bg-blue-aqua hover:bg-blue-navy'>
            Mehr Informationen
          </Link>
          <p className='text-sm text-center my-3'>* alle Preise exkl. MWST</p>
        </div>
      </motion.div>
    </div>
  );
};

export default PricingCard;
