import React from 'react';
import CartItem from './cartItem';
import { motion } from 'framer-motion';
import { GridAnimationContainer } from '../../lib/animationHelper';

type CartItemListProps = {
  lineItems: any[];
};

const CartItemList = ({ lineItems }: CartItemListProps) => {
  return (
    <motion.div variants={GridAnimationContainer} className="py-3">
      {lineItems.map((item: any) => (
        <CartItem key={item.id} lineItem={item}></CartItem>
      ))}
    </motion.div>
  );
};

export default CartItemList;
