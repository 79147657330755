import Particles from 'preact-particles';
import React from 'react';
import HeaderWave from './headerWave';

type LayoutProps = {
  children: any;
};

const ParticlesHero = ({ children }: LayoutProps) => {
  return (
    <div className='relative'>
      <Particles
        id='tsparticles'
        options={{
          fpsLimit: 30,
          interactivity: {
            detectsOn: 'canvas',
            modes: {
              bubble: {
                distance: 400,
                duration: 500,
                opacity: 1,
                size: 10,
              },
            },
          },
          particles: {
            color: {
              value: '#fff',
            },
            links: {
              color: '#fff',
              distance: 75,
              enable: true,
              opacity: 0.75,
              width: 2,
            },
            collisions: {
              enable: true,
            },
            move: {
              direction: 'none',
              enable: true,
              outMode: 'bounce',
              random: false,
              speed: 0.9,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                value_area: 800,
              },
              value: 30,
            },
            opacity: {
              value: 0.4,
            },
            shape: {
              type: 'triangle',
            },
            size: {
              random: true,
              value: 5,
            },
          },
          detectRetina: true,
        }}
      />
      {children}
      <HeaderWave></HeaderWave>
    </div>
  );
};

export default ParticlesHero;
