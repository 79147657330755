import React, { useState } from 'react';
import PortableText from '../core/portableText';
import { Transition } from '@headlessui/react';
import { FaqMetadataProps } from '../../lib/sanity-types/faq';

const FaqItem = (props: FaqMetadataProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="flex flex-col items-center p-4 mb-6 border-1 border-gray-100 border-opacity-20 shadow rounded-lg">
      <button
        className="flex w-full text-left"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <div className="w-full flex flex-row content-center items-center">
          <span className="flex-grow text-lg">{props.question}</span>
          <div className="ml-auto">
            {!isOpen && (
              <svg
                className="text-blue-aqua w-8 h-8 flex-shrink-0"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            )}
            {isOpen && (
              <svg
                className="text-blue-aqua w-8 h-8 flex-shrink-0"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
              </svg>
            )}
          </div>
        </div>
      </button>
      <Transition
        show={isOpen}
        enter="transition duration-650 ease-out"
        enterFrom="transform opacity-0"
        enterTo="transform opacity-100"
        leave="transition duration-450 ease-out"
        leaveFrom="transform  opacity-100"
        leaveTo="transform  opacity-0"
        className="w-full"
      >
        {() => (
          <div className="mt-8">
            <PortableText blocks={props.answer}></PortableText>
          </div>
        )}
      </Transition>
    </div>
  );
};

export default FaqItem;
