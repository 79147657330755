import React from 'react';
import PricingCard from './pricingCard';
import { DataCenterMetadataProps } from '../../lib/sanity-types/data-center';
import { PricingGridProps } from '../../lib/sanity-types/pricing-grid';

const PricingGrid = (props: PricingGridProps) => {
  return (
    <div className="flex flex-wrap justify-center -mx-2 overflow-hidden sm:-mx-2 md:-mx-2 lg:-mx-2 xl:-mx-2 pb-6">
      {props.content.map((dataCenter: DataCenterMetadataProps) => (
        <PricingCard key={dataCenter.id} {...dataCenter} />
      ))}
    </div>
  );
};

export default PricingGrid;
