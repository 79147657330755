import React from 'react';
import Layout from '../components/core/layout';
import Hero from '../components/core/hero';
import ParticlesHero from '../components/core/particlesHero';
import Section from '../components/section/section';
import FeaturedHardware from '../components/hardware/featuredHardware';
import { graphql } from 'gatsby';
import { createFooterProps, mapEdgesToNodes } from '../lib/helpers';

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query PageTemplateQuery($id: String) {
    siteConfig: allSanitySiteConfiguration {
      edges {
        node {
          id
          title
          mainImage {
            ...SanityImage
            altText
          }
          mainNavigation {
            id
            url
            navigationMenuName
          }
          footerTagline
          footerSocialMedia {
            id
            link
            name
            icon
          }
          footerProductLinks {
            id
            navigationMenuName
            url
          }
          footerGeneralLinks {
            id
            navigationMenuName
            url
          }
          footerCompanyLinks {
            id
            navigationMenuName
            url
          }
        }
      }
    }

    currentPage: allSanityPage(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          _rawContent(resolveReferences: { maxDepth: 50 })
          seo {
            title
            description
            keywords
            ogImage {
              asset {
                altText
                gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`;

const PageTemplate = ({ data }: any) => {
  const siteConfig = data && data.siteConfig && mapEdgesToNodes(data.siteConfig)[0];
  const currentPage = data && data.currentPage && mapEdgesToNodes(data.currentPage)[0];
  const footerProps = createFooterProps(siteConfig);

  const content = (currentPage._rawContent || []).map((c: any) => {
    let element = null;

    switch (c._type) {
      case 'particlesContainerComponent':
        let innerContentList: any[] = [];
        c.content.map((innerContent: any, index: number) => {
          switch (innerContent._type) {
            case 'heroComponent':
              innerContentList.push(<Hero key={index} {...innerContent} />);
              break;
            case 'featuredProductsComponent':
              innerContentList.push(<FeaturedHardware key={index} {...innerContent} />);
              break;
            default:
              break;
          }
        });
        element = <ParticlesHero key={c._key}>{innerContentList}</ParticlesHero>;
        break;
      case 'section':
        element = <Section key={c._key} {...c} />;
        break;
      default:
        element = null;
    }
    return element;
  });

  return (
    <Layout
      seoMetadata={currentPage.seo}
      navigationItems={siteConfig.mainNavigation}
      mainImage={siteConfig.mainImage}
      footerProps={footerProps}>
      <div>{content}</div>
    </Layout>
  );
};

export default PageTemplate;
