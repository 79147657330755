import React from 'react';
import { TwoColumnTextGridProps } from '../../lib/sanity-types/two-column-text-grid';

const TwoColumnTextGrid = (props: TwoColumnTextGridProps) => {
  return (
    <section className="py-8 px-4">
      <div className="flex flex-wrap -mx-2">
        <div className="lg:w-1/2 px-2 lg:pr-16 mb-6 lg:mb-0">
          <h2 className="text-lg md:text-xl font-universBoldCondensed">{props.heading}</h2>
        </div>
        <div className="lg:w-1/2 px-2 text-center lg:text-left">
          <p className="mb-6">{props.text}</p>
        </div>
      </div>
    </section>
  );
};

export default TwoColumnTextGrid;
