import React from 'react';
import { TeammemberMetadataProps } from '../../lib/sanity-types/teammember';
import { TeammemberGridProps } from '../../lib/sanity-types/teammember-grid';
import TeammemberGridCard from './teammemberGridCard';
import { motion } from 'framer-motion';
import { GridAnimationContainer } from '../../lib/animationHelper';

const TeammemberGrid = (props: TeammemberGridProps) => {
  return (
    <div className="mt-6 md:mt-16">
      <motion.div variants={GridAnimationContainer} className="flex flex-wrap justify-evenly -m-4 overflow-hidden">
        {props.content.map((teammember: TeammemberMetadataProps) => (
          <TeammemberGridCard key={teammember.id} {...teammember}></TeammemberGridCard>
        ))}
      </motion.div>
    </div>
  );
};

export default TeammemberGrid;
