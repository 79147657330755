import React from 'react';
import { motion } from 'framer-motion';
import { PostGridProps } from '../../lib/sanity-types/post-grid';
import { PostMetadataProps } from '../../lib/sanity-types/post';
import PostCard from './postCard';
import { GridAnimationContainer } from '../../lib/animationHelper';

const PostGrid = (props: PostGridProps) => {
  return (
    <div className="container px-1 py-6 md:py-8 mx-auto">
      <motion.div variants={GridAnimationContainer} className="-mx-4 flex flex-wrap">
        {props.content.map((post: PostMetadataProps) => (
          <PostCard key={post.id} {...post}></PostCard>
        ))}
      </motion.div>
    </div>
  );
};

export default PostGrid;
