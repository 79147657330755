import React from 'react';
import { PartnerMetadataProps } from '../../lib/sanity-types/partner';
import { getGatsbyImage } from '../../lib/helpers';

const PartnerCard = (props: PartnerMetadataProps) => {
  const partnerImageComponent = getGatsbyImage(props.mainImage);

  return (
    <div className="mb-4 w-full md:w-1/2 lg:w-1/4 px-2">
      <div className="py-12 flex justify-center items-center">
        <a href={props.link.href} target="_blank" rel="noopener" className="cursor-pointer">
          <div className="w-44 md:w-60 transition duration-500 ease-in-out transform hover:scale-105">
            {partnerImageComponent}
          </div>
        </a>
      </div>
    </div>
  );
};

export default PartnerCard;
