import { motion } from 'framer-motion';
import { GridAnimationContainer } from '../../lib/animationHelper';
import { DataCenterMetadataProps } from '../../lib/sanity-types/data-center';
import { FacilitiesGridProps } from '../../lib/sanity-types/facilities-grid';
import FacilitiesCard from './facilitiesCard';

const FacilitiesGrid = (props: FacilitiesGridProps) => {
  return (
    <div className="container px-1 py-6 md:py-8 mx-auto mt-28">
      <motion.div
        variants={GridAnimationContainer}
        className="flex flex-wrap justify-center -mx-2 sm:-mx-2 md:-mx-2 lg:-mx-4 xl:-mx-4"
      >
        {props.content.map((dataCenter: DataCenterMetadataProps) => (
          <FacilitiesCard key={dataCenter.id} {...dataCenter}></FacilitiesCard>
        ))}
      </motion.div>
    </div>
  );
};

export default FacilitiesGrid;
