import React from 'react';
import FadeInContainer from '../core/fadeInContainer';

const SectionSubtitle = ({ subtitle }: { subtitle: string }) => {
  return (
    <FadeInContainer>
      <h2 className="text-lg text-center lg:mx-20 mb-16 xl:mb-20">{subtitle}</h2>
    </FadeInContainer>
  );
};

export default SectionSubtitle;
