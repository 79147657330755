import React from 'react';
import { navigate } from 'gatsby';
import { CtaMetadataProps } from '../../lib/sanity-types/cta';

type CtaProps = {
  classes: string;
};

const doNavigate = (location: string, isInternal: boolean) => {
  if (isInternal) {
    navigate(location);
  } else {
    window.open(location, '_blank');
  }
};

const Cta = (props: CtaProps & CtaMetadataProps) => {
  const isInternal = props.linkType === 'path';
  const linkToUse: string = isInternal ? props.route : props.link;

  return (
    <button onClick={() => doNavigate(linkToUse, isInternal)} className={props.classes || ''}>
      {props.title}
    </button>
  );
};

export default Cta;
