import React from 'react';
import { navigate } from 'gatsby';
import { motion } from 'framer-motion';
import { PostMetadataProps } from '../../lib/sanity-types/post';
import { GridAnimationItem } from '../../lib/animationHelper';
import { getGatsbyImage } from '../../lib/helpers';

const PostCard = (props: PostMetadataProps) => {
  props.mainImage.classes = 'lg:h-60 md:h-36 w-full object-cover object-center rounded-t-lg';

  const formattedPublishDate = new Date(props.publishDate).toLocaleDateString('de-DE');
  const postImageComponent = getGatsbyImage(props.mainImage);

  const handleClick = (slug: string) => {
    navigate(`/blog/${slug}`);
  };

  return (
    <div className="w-full flex flex-col p-4 sm:w-1/2 lg:w-1/3">
      <motion.div
        variants={GridAnimationItem}
        className="flex flex-col flex-1 bg-white rounded-lg shadow-lg cursor-pointer"
        whileHover={{ scale: 1.02 }}
        onClick={() => handleClick(props.slug.current)}
      >
        <div>{postImageComponent}</div>
        <div className="p-6">
          <h2 className="tracking-widest text-small font-universBoldCondensed text-gray-400 mb-1">
            {props.category.title}
          </h2>
          <h1 className="text-lg mb-3">{props.title}</h1>
          <p className="leading-relaxed mb-3">{props.excerpt}</p>
        </div>
        <div className="p-6 mt-auto flex flex-col items-start text-xs md:flex md:flex-row md:items-center md:flex-wrap md:text-small">
          <div className="text-blue-aqua inline-flex items-center md:mb-2 lg:mb-0">
            Mehr erfahren
            <svg
              className="w-4 h-4 ml-2"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M5 12h14" />
              <path d="M12 5l7 7-7 7" />
            </svg>
          </div>
          <span className=" text-gray-400 mr-3 md:ml-auto leading-none pr-3 py-1 md:border-r-2 md:border-gray-200">
            by {props.author}
          </span>
          <span className="text-gray-400 leading-none text-sm">{formattedPublishDate}</span>
        </div>
      </motion.div>
    </div>
  );
};

export default PostCard;
