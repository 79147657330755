import React from 'react';
import 'swiper/swiper-bundle.min.css';
import TestimonialCard from './testimonialCard';
import SwiperCore, { Pagination, Autoplay, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { TestimonialItemMetadataProps, TestimonialMetadataProps } from '../../lib/sanity-types/testimonial';
import FadeInContainer from '../core/fadeInContainer';

SwiperCore.use([Pagination, Autoplay, EffectFade]);

const TestimonialSwiper = (props: TestimonialMetadataProps) => {
  return (
    <FadeInContainer>
      <div className="container">
        <Swiper
          color="red"
          slidesPerView={1}
          pagination={{ clickable: true }}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          effect="fade"
          fadeEffect={{
            crossFade: true,
          }}
        >
          {props.content.map((testimonial: TestimonialItemMetadataProps) => (
            <SwiperSlide key={testimonial.id}>
              <TestimonialCard {...testimonial} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </FadeInContainer>
  );
};

export default TestimonialSwiper;
