import React from 'react';
import { GridItemProps, GridProps } from '../../lib/sanity-types/grid';
import PropertiesGridCard from './propertiesGridCard';

const PropertiesGrid = (props: GridProps) => {
  return (
    <div className="flex flex-wrap overflow-hidden text-center">
      {props.content.map((item: GridItemProps) => (
        <PropertiesGridCard
          key={item._key}
          title={item.title}
          description={item.description}
          iconName={item.icon}
        ></PropertiesGridCard>
      ))}
    </div>
  );
};

export default PropertiesGrid;
