import React from 'react';
import { TeammemberMetadataProps } from '../../lib/sanity-types/teammember';
import { motion } from 'framer-motion';
import { GridAnimationItem } from '../../lib/animationHelper';
import { getGatsbyImage } from '../../lib/helpers';

const TeammemberGridCard = (props: TeammemberMetadataProps) => {
  props.mainImage.classes = 'rounded-t-lg object-top w-full h-full';
  const teammemberImageComponent = getGatsbyImage(props.mainImage);

  return (
    <div className='p-4 overflow-hidden w-full md:w-1/2 lg:w-1/3'>
      <motion.div
        variants={GridAnimationItem}
        whileHover={{ scale: 1.02 }}
        className='h-full flex flex-col items-center text-center border-1 border-gray-100 border-opacity-20 shadow rounded-lg'>
        <div className='h-72 mb-4 w-full object-cover object-center'>
          {teammemberImageComponent}
        </div>
        <div className='w-full p-2'>
          <h2 className='font-universBoldCondensed text-lg'>{props.name}</h2>
          <h3 className='text-gray-600 mb-3'>{props.position}</h3>
          <p className='mb-4'>{props.description}</p>
        </div>
      </motion.div>
    </div>
  );
};

export default TeammemberGridCard;
