import React from 'react';
import ProductCard from './hardwareCard';
import { motion } from 'framer-motion';
import { GridAnimationContainer } from '../../lib/animationHelper';
import { ShopifyCollection } from '../../lib/shopify-types/shopifyCollection';
import { ProductContext } from '../../context/product-context';
import { ShopifyProduct } from '../../lib/shopify-types/shopifyProduct';

const allProductsCollectionName: string = 'Alle Miner';

const Hardware = () => {
  const { collections } = React.useContext(ProductContext);

  const allProductsCollection: ShopifyCollection =
    collections.find((collection: ShopifyCollection) => collection.title === allProductsCollectionName) ||
    (undefined as any);

  return (
    <div>
      {allProductsCollection?.products?.length > 0 ? (
        <motion.div
          variants={GridAnimationContainer}
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8"
        >
          {allProductsCollection.products.map((product: ShopifyProduct) => (
            <ProductCard
              key={product?.shopifyId}
              title={product?.title}
              price={product?.priceRangeV2?.minVariantPrice?.amount}
              currency={product?.priceRangeV2?.minVariantPrice?.currencyCode}
              image={product.images[0]?.localFile}
              imageAltText={product.images[0]?.altText}
              slug={product.handle}
            />
          ))}
        </motion.div>
      ) : (
        <h1>Empty</h1>
      )}
    </div>
  );
};

export default Hardware;
