import React from 'react';
import PartnerCard from './partnerCard';
import { PartnerGridProps } from '../../lib/sanity-types/partner-grid';
import { PartnerMetadataProps } from '../../lib/sanity-types/partner';
import FadeInContainer from '../core/fadeInContainer';

const PartnerGrid = (props: PartnerGridProps) => {
  return (
    <FadeInContainer>
      <div className="flex flex-wrap -mx-2 w-full justify-center">
        {props.content.map((partner: PartnerMetadataProps) => (
          <PartnerCard key={partner.id} {...partner} />
        ))}
      </div>
    </FadeInContainer>
  );
};

export default PartnerGrid;
