import React from 'react';
import tw, { styled } from 'twin.macro';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { formatPrice } from '../../lib/formatPrice';
import { GridAnimationItem } from '../../lib/animationHelper';

type ProductCardProps = {
  usedForFeaturedProducts?: boolean;
  image: any;
  imageAltText: string;
  title: string;
  price: string;
  currency: string;
  slug: string;
};

interface TitlePriceProps {
  usedForFeaturedProducts?: boolean;
}

const ProductCardWrapper = styled.div`
  ${tw`flex flex-col h-full px-4 pb-4`}
  ${({ usedForFeaturedProducts }: TitlePriceProps) =>
    !usedForFeaturedProducts && tw`bg-white rounded-lg shadow`}
`;

const ProductCardImageWrapper = styled.div`
  ${tw`h-72 rounded overflow-hidden mt-4`}
  ${({ usedForFeaturedProducts }: TitlePriceProps) => usedForFeaturedProducts && tw`bg-white`}
`;

const Title = styled.p`
  ${tw`text-lg font-universBoldCondensed uppercase`};
  ${({ usedForFeaturedProducts }: TitlePriceProps) => usedForFeaturedProducts && tw`text-white`}
`;

const Price = styled.p`
  ${tw`mt-4 text-lg`};
  ${({ usedForFeaturedProducts }: TitlePriceProps) => usedForFeaturedProducts && tw`text-white`}
`;

const SelectionButton = styled.button`
  ${tw`inline-block overflow-hidden py-2 px-2 leading-none font-universBoldCondensed rounded shadow transition-colors duration-150`}
  ${({ usedForFeaturedProducts }: TitlePriceProps) =>
    usedForFeaturedProducts
      ? tw`bg-white hover:bg-blue-navy hover:text-white`
      : tw`text-white bg-blue-aqua hover:bg-blue-navy`}
`;

const ButtonWrapper = styled.div`
  ${tw`mt-auto`};
`;

const HardwareCard = ({
  usedForFeaturedProducts = false,
  image,
  imageAltText,
  title,
  price,
  currency,
  slug,
}: ProductCardProps) => {
  const formattedPrice = formatPrice(currency, Number(price));
  const productImage = getImage(image);
  const productDetailLink: string = `/hardware/${slug}`;

  return (
    <motion.div variants={GridAnimationItem} whileHover={{ scale: 1.02 }}>
      <ProductCardWrapper usedForFeaturedProducts={usedForFeaturedProducts}>
        <ProductCardImageWrapper usedForFeaturedProducts={usedForFeaturedProducts}>
          {productImage && (
            <GatsbyImage
              className='object-cover object-center w-full h-full'
              image={productImage}
              alt={imageAltText ? imageAltText : ''}
            />
          )}
        </ProductCardImageWrapper>
        <div className='my-12'>
          <Title usedForFeaturedProducts={usedForFeaturedProducts}>{title}</Title>
          <Price usedForFeaturedProducts={usedForFeaturedProducts}>Ab {formattedPrice}</Price>
        </div>
        <ButtonWrapper>
          <Link to={productDetailLink}>
            <SelectionButton usedForFeaturedProducts={usedForFeaturedProducts}>
              Ausführung wählen
            </SelectionButton>
          </Link>
        </ButtonWrapper>
      </ProductCardWrapper>
    </motion.div>
  );
};

export default HardwareCard;
