import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ContactGridProps } from '../../lib/sanity-types/contact-grid';
import { useFormspark } from '@formspark/use-formspark';
import { getGatsbyImage } from '../../lib/helpers';

const FORMSPARK_FORM_ID = 'wgEzXmNm';

const ContactGrid = (props: ContactGridProps) => {
  props.mainImage.classes = 'mb-6';

  const contactImageComponent = getGatsbyImage(props.mainImage);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm();

  const [submit, submitting] = useFormspark({
    formId: FORMSPARK_FORM_ID,
  });

  const onSubmit = async (data: any) => {
    await submit({ ...data });
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  return (
    <div className="flex flex-wrap items-start overflow-hidden xl:-mx-4">
      <div className="w-full overflow-hidden md:my-2 md:px-2 md:w-full lg:my-4 lg:px-4 lg:w-2/5 xl:my-4 xl:px-4 xl:w-2/5 bg-transparent">
        {contactImageComponent}
      </div>
      <div className="w-full overflow-hidden md:my-2 md:px-2 md:w-full lg:my-4 lg:px-4 lg:w-3/5 xl:my-4 xl:px-4 xl:w-3/5">
        <div className="max-w-md mx-auto">
          <h2 className="mb-6 text-lg">{props.subtitle}</h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <input type="hidden" name="_redirect" defaultValue="false" />
            <div className="mb-4">
              <input
                className="w-full p-4 leading-none bg-white rounded outline-none"
                type="text"
                placeholder="Vorname*"
                required
                {...register('firstname', { required: true, maxLength: 80 })}
              />
              {errors.firstname?.type === 'required' && (
                <div className="mx-3 ml-2 text-small text-red-500 ">Vorname ist erforderlich</div>
              )}
              {errors.firstname?.type === 'maxLength' && (
                <div className="mx-3 ml-2 text-small text-red-500 ">Vorname darf nicht länger als 80 Zeichen sein</div>
              )}
            </div>
            <div className="mb-4">
              <input
                className="w-full p-4 leading-none bg-white rounded outline-none"
                type="text"
                placeholder="Nachname*"
                required
                {...register('lastname', { required: true, maxLength: 80 })}
              />
              {errors.lastname?.type === 'required' && (
                <div className="mx-3 ml-2 text-small text-red-500 ">Nachname ist erforderlich</div>
              )}
              {errors.lastname?.type === 'maxLength' && (
                <div className="mx-3 ml-2 text-small text-red-500 ">Nachname darf nicht länger als 80 Zeichen sein</div>
              )}
            </div>
            <div className="mb-4">
              <input
                className="w-full p-4 leading-none bg-white rounded outline-none"
                type="text"
                placeholder="Email*"
                required
                {...register('email', { required: true })}
              />
              {errors.email?.type === 'required' && (
                <div className="mx-3 ml-2 text-small text-red-500 ">Email ist erforderlich</div>
              )}
            </div>
            <div className="mb-4">
              <input
                className="w-full p-4 leading-none bg-white rounded outline-none"
                type="tel"
                placeholder="Telefon*"
                required
                {...register('phone', { required: true, maxLength: 12 })}
              />
              {errors.phone?.type === 'required' && (
                <div className="mx-3 ml-2 text-small text-red-500 ">Telefon ist erforderlich</div>
              )}
            </div>
            <div className="mb-4">
              <textarea
                className="w-full p-4 leading-none h-64 bg-white rounded outline-none"
                placeholder="Nachricht*"
                defaultValue={''}
                required
                {...register('message', { required: true })}
              />
              {errors.message?.type === 'required' && (
                <div className="mx-3 ml-2 text-small text-red-500 ">Nachricht ist erforderlich</div>
              )}
            </div>
            <div className="flex justify-between items-center">
              <button
                disabled={submitting}
                type="submit"
                className="disabled:opacity-50 disabled:cursor-wait inline-flex items-center w-full text-center overflow-hidden py-3 px-6 leading-none font-universBoldCondensed rounded shadow transition-colors duration-150 text-white bg-blue-aqua hover:bg-blue-navy"
              >
                {submitting && Object.keys(errors).length === 0 && (
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle className="opacity-25" cx={12} cy={12} r={10} stroke="currentColor" strokeWidth={4} />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                  </svg>
                )}
                Absenden
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactGrid;
