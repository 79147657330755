import React from 'react';
import FaqItem from './faqItem';
import { motion } from 'framer-motion';
import { FaqListProps } from '../../lib/sanity-types/faq-list';
import { FaqMetadataProps } from '../../lib/sanity-types/faq';
import { GridAnimationContainer, GridAnimationItem } from '../../lib/animationHelper';

const FaqList = (props: FaqListProps) => {
  return (
    <div className="mt-10 lg:mt-14 pb-56 w-full md:w-10/12 lg:w-8/12">
      <motion.div variants={GridAnimationContainer}>
        {props.content.map((faq: FaqMetadataProps) => (
          <motion.div key="{faq.id}" variants={GridAnimationItem}>
            <FaqItem {...faq} />
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
};

export default FaqList;
