import React from 'react';
import FadeInContainer from '../core/fadeInContainer';
import FaIcon from '../core/faIcon';

type PropertiesGridCardProps = {
  title: string;
  description: string;
  iconName: string;
};

const PropertiesGridCard = ({ title, description, iconName }: PropertiesGridCardProps) => {
  return (
    <div className="py-4 md:w-1/3 md:px-6 mb-4 md:mb-0">
      <FadeInContainer>
        <FaIcon color={'text-blue-aqua'} iconName={iconName} size={'fa-3x'} wrapperSpacing={'mb-6'}></FaIcon>
        <p className="font-universBoldCondensed mb-3 text-lg uppercase">{title}</p>
        <p className="leading-relaxed">{description}</p>
      </FadeInContainer>
    </div>
  );
};

export default PropertiesGridCard;
