export const GridAnimationContainer = {
  enter: {
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.15,
    },
  },
};

export const GridAnimationItem = {
  initial: { y: 20, opacity: 0 },
  enter: {
    y: 0,
    opacity: 1,
  },
};
