import React from 'react';
import CartItemList from './cartItemList';
import MaxWidthContainer from '../core/maxWidthContainer';
import { StoreContext } from '../../context/store-context';
import { Link } from 'gatsby';
import { formatPrice } from '../../lib/formatPrice';

const Cart = () => {
  const { checkout, loading } = React.useContext(StoreContext);
  const emptyCart = checkout.lineItems.length === 0;

  const handleCheckout = () => {
    window.open(checkout.webUrl);
  };

  return (
    <MaxWidthContainer>
      {emptyCart ? (
        <div className="text-center">
          <p className="text-base">
            Sieht so aus, als hättest Du noch nichts gefunden. Wir verstehen, dass es manchmal schwer ist, sich zu
            entscheiden - vielleicht hilft das:
          </p>
          <div className="mt-8">
            <Link
              to="/hardware"
              className="font-universBoldCondensed text-base text-blue-aqua hover:text-blue-navy transition-colors duration-250 ease-in-out"
            >
              Unser Angebot an Miner
            </Link>
          </div>
        </div>
      ) : (
        <div>
          <CartItemList lineItems={checkout.lineItems} />
          <div className="mt-3 md:mt-6 py-3 md:py-6 border-t-2 border-gainsboro">
            <div className="flex flex-col items-end">
              <div className="flex flex-col items-start mb-3">
                <span className="text-lg">Total</span>
                <span className="text-titleSmallMobile font-universBoldCondensed">
                  {formatPrice(checkout.totalPriceV2.currencyCode, checkout.totalPriceV2.amount)}
                </span>
              </div>

              <button
                onClick={handleCheckout}
                disabled={loading}
                className="text-white bg-blue-aqua hover:bg-blue-navy px-4 py-3 rounded-lg font-universBoldCondensed tracking-widest uppercase focus:outline-none"
              >
                Checkout
              </button>
            </div>
          </div>
        </div>
      )}
    </MaxWidthContainer>
  );
};

export default Cart;
