import React, { useCallback, useContext, useMemo, useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getShopifyImage } from 'gatsby-source-shopify';
import { motion } from 'framer-motion';
import { GridAnimationItem } from '../../lib/animationHelper';
import { StoreContext } from '../../context/store-context';
import { formatPrice } from '../../lib/formatPrice';
import { debounce } from 'lodash';
import toast from 'react-hot-toast';

interface CartItemProps {
  lineItem: any;
}

const CartItem = ({ lineItem }: CartItemProps) => {
  const { removeLineItem, checkout, updateLineItem } = useContext(StoreContext);
  const [quantity, setQuantity] = useState(lineItem.quantity);

  const variantImage = {
    ...lineItem.variant.image,
    originalSrc: lineItem.variant.image.src,
  };

  const price = formatPrice(lineItem.variant.priceV2.currencyCode, Number(lineItem.variant.priceV2.amount));
  const { selectedOptions } = lineItem.variant;
  
  const subtotal = formatPrice(
    lineItem.variant.priceV2.currencyCode,
    Number(lineItem.variant.priceV2.amount) * quantity
  );

  const handleRemove = () => {
    removeLineItem(checkout.id, lineItem.id);
    toast(`"${lineItem.title}" aus dem Warenkorb entfernt!`, {
      duration: 4000,
      position: 'top-right',
      style: {
        minWidth: '250px',
        border: '1px solid #00F2EE',
        padding: '16px',
      },
    });
  };

  const uli = debounce((value) => updateLineItem(checkout.id, lineItem.id, value), 300);

  const debouncedUli = useCallback((value) => uli(value), []);

  const handleQuantityChange = (value: any) => {
    if (value !== '' && Number(value) < 1) {
      return;
    }

    setQuantity(value);

    if (Number(value) >= 1) {
      debouncedUli(value);
    }
  };

  const doIncrement = () => {
    handleQuantityChange(Number(quantity || 0) + 1);
  };

  const doDecrement = () => {
    handleQuantityChange(Number(quantity || 0) - 1);
  };

  const image = useMemo(
    () =>
      getShopifyImage({
        image: variantImage,
        layout: 'constrained',
        width: 160,
        height: 160,
      }),
    [variantImage.src]
  );

  return (
    <motion.div
      variants={GridAnimationItem}
      className="flex items-center border-1 bg-white border-gray-100 border-opacity-20 shadow rounded-lg py-3 md:py-6 md:px-6 mb-4 md:mb-8 w-full"
    >
      <div className="w-2/5 flex flex-grow items-center">
        <div className="hidden md:inline-flex h-16 md:h-24 w-16 md:w-24 mr-4 p-1 rounded-lg">
          {image && (
            <GatsbyImage key={variantImage.src} image={image} alt={variantImage.altText ?? lineItem.variant.title} />
          )}
        </div>

        <div className="p-1 pl-4 md:p-0 md:pl-0">
          <h4 className="md:text-lg">{lineItem.title}</h4>

          {selectedOptions && (
            <div className="my-3 text-xs md:text-small">
              <ul>
                {selectedOptions?.map((option: any) => (
                  <li>
                    <span className="font-universBoldCondensed inline-block w-16">{option.name}:</span>
                    <span className="inline-block">{option.value}</span>
                  </li>
                ))}
              </ul>
            </div>
          )}

          <button className="hover:text-blue-aqua text-xs flex items-center focus:outline-none" onClick={handleRemove}>
            <svg className="fill-current w-3 h-3 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
            </svg>
            Entfernen
          </button>
        </div>
      </div>

      <div className="flex flex-col items-center w-2/5 ">
        <button className="hover:text-blue-aqua focus:outline-none p-1" onClick={doIncrement}>
          <svg className="w-5 h-5 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M10.707 7.05L10 6.343 4.343 12l1.414 1.414L10 9.172l4.243 4.242L15.657 12z" />
          </svg>
        </button>

        <span className="mx-3 md:mx-6 p-1">{quantity}</span>

        <button className="hover:text-blue-aqua focus:outline-none p-1" onClick={doDecrement}>
          <svg className="w-5 h-5 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </button>
      </div>

      <div className="text-right md:w-1/5 pr-4 md:pr-6">
        <p className="md:text-lg font-universBoldCondensed">{subtotal}</p>
        {quantity > 1 && <p className="text-sm">{price} pro Stück</p>}
      </div>
    </motion.div>
  );
};

export default CartItem;
