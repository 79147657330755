import { motion } from 'framer-motion';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import { GridAnimationItem } from '../../lib/animationHelper';
import { DataCenterMetadataProps } from '../../lib/sanity-types/data-center';
import { sanityConfig } from '../../lib/sanityConfig';

const FacilitiesCard = (props: DataCenterMetadataProps) => {
  const imageData = getGatsbyImageData(props.mainImage, {}, sanityConfig);
  console.log(props);

  return (
    <motion.div
      variants={GridAnimationItem}
      whileHover={{ scale: 1.02 }}
      className='my-4 px-2 w-full overflow-visible sm:my-4 sm:px-2 sm:w-full md:my-4 md:px-2 md:w-1/2 lg:my-2 lg:px-4 xl:my-2 xl:px-4 xl:w-1/3'>
      <div className='flex flex-col w-full h-full bg-white rounded-lg shadow'>
        <div>
          {imageData && (
            <GatsbyImage
              className='lg:h-60 md:h-36 w-full object-cover object-center rounded-t-lg'
              image={imageData}
              alt={props.mainImage.altText}
            />
          )}
        </div>
        <div className='p-6'>
          <h1 className='text-lg mb-3 font-universBoldCondensed'>{props.name}</h1>
          <p className='leading-relaxed mb-3 break-words'>{props.description}</p>
          <div className='mt-4'>
            <h3 className='mb-3 font-universBoldCondensed'>Beschreibung</h3>
            <ul className='mb-8 list-inside list-disc'>
              {props.properties.map((property: string, index: number) => {
                return (
                  <li key={index} className='mb-1'>
                    {property}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className='mb-6 mt-auto'>
          <div className='w-full flex flex-row items-center justify-between mx-6'>
            <div className='w-auto flex flex-row items-end py-1'>
              <p className='leading-none font-universBoldCondensed w-20'>Preis</p>
              <p className='leading-none'>
                {props.price} {props.currency}
              </p>
            </div>
          </div>
          <div className='w-full flex flex-row items-center justify-between mx-6'>
            <div className='w-auto flex flex-row items-end py-1'>
              <p className='leading-none font-universBoldCondensed w-20'>Kapazität</p>
              <p className='leading-none'>{props.capacity}</p>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default FacilitiesCard;
