import React from 'react';
import { ContactGridProps } from '../../lib/sanity-types/contact-grid';
import { CtaMetadataProps } from '../../lib/sanity-types/cta';
import { FacilitiesGridProps } from '../../lib/sanity-types/facilities-grid';
import { FaqListProps } from '../../lib/sanity-types/faq-list';
import { GridProps } from '../../lib/sanity-types/grid';
import { PartnerGridProps } from '../../lib/sanity-types/partner-grid';
import { PostGridProps } from '../../lib/sanity-types/post-grid';
import { PricingGridProps } from '../../lib/sanity-types/pricing-grid';
import { RichTextContentProps } from '../../lib/sanity-types/rich-text-content';
import { SectionMetadata, SectionSubTitleProps, SectionTitleMetadataProps } from '../../lib/sanity-types/section';
import { TeammemberGridProps } from '../../lib/sanity-types/teammember-grid';
import { TestimonialMetadataProps } from '../../lib/sanity-types/testimonial';
import { TwoColumnTextGridProps } from '../../lib/sanity-types/two-column-text-grid';
import ContactGrid from '../contact/contactGrid';
import Cta from '../core/cta';
import FacilitiesGrid from '../facilities/facilitiesGrid';
import FaqList from '../faq/faqList';
import FooterSpacer from '../core/footerSpacer';
import MaxWidthContainer from '../core/maxWidthContainer';
import PartnerGrid from '../partner/partnerGrid';
import PortableText from '../core/portableText';
import PostGrid from '../blog/postGrid';
import PricingGrid from '../pricing/pricingGrid';
import PropertiesGrid from '../properties/propertiesGrid';
import SectionContainer from './sectionContainer';
import SectionSubtitle from './sectionSubtitle';
import SectionTitle from './sectionTitle';
import TeammemberGrid from '../team/teammemberGrid';
import TestimonialSection from '../testimonial/testimonialSwiper';
import TwoColumnTextGrid from '../core/twoColumnTextGrid';
import Cart from '../cart/cart';
import Hardware from '../hardware/hardware';
import FadeInContainer from '../core/fadeInContainer';

const Section = (props: SectionMetadata) => {
  const content = (props.content || []).map(
    (
      c:
        | SectionTitleMetadataProps
        | SectionSubTitleProps
        | GridProps
        | PricingGridProps
        | CtaMetadataProps
        | TestimonialMetadataProps
        | PartnerGridProps
        | TeammemberGridProps
        | TwoColumnTextGridProps
        | FaqListProps
        | ContactGridProps
        | PostGridProps
        | RichTextContentProps
    ) => {
      let element = null;

      switch (c._type) {
        case 'sectionTitleComponent':
          var sectionTitleObj = c as SectionTitleMetadataProps;
          element = <SectionTitle {...sectionTitleObj} key={c._key}></SectionTitle>;
          break;
        case 'sectionSubtitleComponent':
          var sectionSubTitleObj = c as SectionSubTitleProps;
          element = <SectionSubtitle subtitle={sectionSubTitleObj.subtitle} key={c._key}></SectionSubtitle>;
          break;
        case 'propertiesGridComponent':
          var propertiesGridObj = c as GridProps;
          element = <PropertiesGrid {...propertiesGridObj} key={c._key}></PropertiesGrid>;
          break;
        case 'pricingGridComponent':
          var pricingGridObj = c as PricingGridProps;
          element = <PricingGrid {...pricingGridObj} key={c._key}></PricingGrid>;
          break;
        case 'partnerGridComponent':
          var partnerGridObj = c as PartnerGridProps;
          element = <PartnerGrid {...partnerGridObj} key={c._key}></PartnerGrid>;
          break;
        case 'testimonialSliderComponent':
          var testimonialObj = c as TestimonialMetadataProps;
          element = <TestimonialSection {...testimonialObj} key={c._key}></TestimonialSection>;
          break;
        case 'productsComponent':
          element = <Hardware key={c._key}></Hardware>;
          break;
        case 'teammemberGridComponent':
          var teammemberGridObj = c as TeammemberGridProps;
          element = <TeammemberGrid {...teammemberGridObj} key={c._key}></TeammemberGrid>;
          break;
        case 'twoColumnTextGridComponent':
          var twoColumnTextGridObj = c as TwoColumnTextGridProps;
          element = <TwoColumnTextGrid {...twoColumnTextGridObj} key={c._key}></TwoColumnTextGrid>;
          break;
        case 'faqListComponent':
          var faqListObj = c as FaqListProps;
          element = <FaqList {...faqListObj} key={c._key}></FaqList>;
          break;
        case 'contactGridComponent':
          var contactGridObj = c as ContactGridProps;
          element = <ContactGrid {...contactGridObj} key={c._key}></ContactGrid>;
          break;
        case 'postGridComponent':
          var postGridObj = c as PostGridProps;
          element = <PostGrid {...postGridObj} key={c._key}></PostGrid>;
          break;
        case 'richTextContentComponent':
          var richTextContentObj = c as RichTextContentProps;
          element = <PortableText blocks={richTextContentObj.richTextContent} key={c._key}></PortableText>;
          break;
        case 'facilitiesGridComponent':
          var facilitiesGridObj = c as FacilitiesGridProps;
          element = <FacilitiesGrid {...facilitiesGridObj} key={c._key}></FacilitiesGrid>;
          break;
        case 'footerSpacerComponent':
          element = <FooterSpacer key={c._key}></FooterSpacer>;
          break;
        case 'cartComponent':
          element = <Cart key={c._key}></Cart>;
          break;
        case 'ctaComponent':
          var ctaObj = c as CtaMetadataProps;
          element = (
            <FadeInContainer>
              <div className="flex flex-col items-center my-16" key={c._key}>
                <Cta
                  classes="inline-block overflow-hidden py-3 px-6 leading-none font-universBoldCondensed text-center rounded shadow focus:shadow-outline transition-colors duration-150 text-white bg-blue-aqua hover:bg-blue-navy"
                  {...ctaObj}
                ></Cta>
              </div>
            </FadeInContainer>
          );
          break;
        default:
          element = null;
      }
      return element;
    }
  );

  return (
    <MaxWidthContainer>
      <SectionContainer>
        <div className="mt-20 lg:mt-32 mb-12 lg:mb-20">
          <div className="flex flex-col items-center">{content}</div>
        </div>
      </SectionContainer>
    </MaxWidthContainer>
  );
};

export default Section;
