import React from 'react';
import tw, { styled } from 'twin.macro';
import { SectionTitleMetadataProps } from '../../lib/sanity-types/section';
import FadeInContainer from '../core/fadeInContainer';

interface StyledSectionTitleProps {
  color?: string;
}

const colorStyleMap: any = {
  default: tw`text-white`,
  navy: tw`text-blue-navy`,
  aqua: tw`text-blue-aqua`,
};

const StyledSectionTitleH1 = styled.h1`
  ${tw`text-sectionTitleMobile sm:text-sectionTitleTablet lg:text-sectionTitle text-center mb-8 xl:mb-12 font-universBoldCondensed uppercase`}
  ${({ color }: StyledSectionTitleProps) => (color ? colorStyleMap[color] : colorStyleMap.default)}
`;

const StyledSectionTitleH2 = styled.h2`
  ${tw`text-sectionTitleMobile sm:text-sectionTitleTablet lg:text-sectionTitle text-center mb-8 xl:mb-12 font-universBoldCondensed uppercase`}
  ${({ color }: StyledSectionTitleProps) => (color ? colorStyleMap[color] : colorStyleMap.default)}
`;

const SectionTitle = (props: SectionTitleMetadataProps) => {
  return (
    <FadeInContainer>
      {props.titleType === 'h1' ? (
        <StyledSectionTitleH1 color={props.titleColor}>{props.title}</StyledSectionTitleH1>
      ) : (
        <StyledSectionTitleH2 color={props.titleColor}>{props.title}</StyledSectionTitleH2>
      )}
    </FadeInContainer>
  );
};

export default SectionTitle;
