import React from 'react';
import MaxWidthContainer from '../core/maxWidthContainer';
import SectionContainer from '../section/sectionContainer';
import SectionTitle from '../section/sectionTitle';
import ProductCard from './hardwareCard';
import { motion } from 'framer-motion';
import { FeaturedProductsMetadataProps } from '../../lib/sanity-types/featured-products';
import { GridAnimationContainer } from '../../lib/animationHelper';
import { SectionTitleMetadataProps } from '../../lib/sanity-types/section';
import { ProductContext } from '../../context/product-context';
import { ShopifyCollection } from '../../lib/shopify-types/shopifyCollection';
import { ShopifyProduct } from '../../lib/shopify-types/shopifyProduct';

const featuredProductsCollectionName: string = 'Vorgestellte Miner';

const FeaturedHardware = (props: FeaturedProductsMetadataProps) => {
  const { collections } = React.useContext(ProductContext);

  const featuredProductsCollection: ShopifyCollection =
    collections.find((collection: ShopifyCollection) => collection.title === featuredProductsCollectionName) ||
    (undefined as any);

  return (
    <MaxWidthContainer>
      <SectionContainer>
        <div className="my-20 lg:my-32">
          <SectionTitle {...({ title: props.sectionTitle } as SectionTitleMetadataProps)}></SectionTitle>
          {featuredProductsCollection?.products?.length > 0 ? (
            <motion.div
              variants={GridAnimationContainer}
              className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8"
            >
              {featuredProductsCollection.products.map((product: ShopifyProduct) => (
                <ProductCard
                  key={product?.shopifyId}
                  title={product?.title}
                  price={product?.priceRangeV2?.minVariantPrice?.amount}
                  currency={product?.priceRangeV2?.minVariantPrice?.currencyCode}
                  image={product.images[0]?.localFile}
                  imageAltText={product.images[0]?.altText}
                  usedForFeaturedProducts={true}
                  slug={product.handle}
                />
              ))}
            </motion.div>
          ) : (
            <h1>Empty</h1>
          )}
        </div>
      </SectionContainer>
    </MaxWidthContainer>
  );
};

export default FeaturedHardware;
