import React from 'react';
import { getGatsbyImage } from '../../lib/helpers';
import { HeroMetadataProps } from '../../lib/sanity-types/hero';
import MaxWidthContainer from './maxWidthContainer';
import SectionContainer from '../section/sectionContainer';
import Cta from './cta';
import FadeInContainer from './fadeInContainer';

const Hero = (props: HeroMetadataProps) => {
  const heroImageComponent = getGatsbyImage(props.mainImage);

  return (
    <MaxWidthContainer>
      <SectionContainer>
        <FadeInContainer>
          <div className="mx-auto pt-12 md:pt-32 pb-10 flex flex-col items-center md:flex-row md:items-start">
            <div className="lg:flex-grow md:w-2/3 lg:pr-24 md:pr-16">
              <div className="flex flex-col mb-16 md:mb-0 items-center md:items-start">
                <h1 className="text-titleXxlMobile sm:text-titleXxlTablet lg:text-titleXxl text-white uppercase font-universBoldCondensed md:text-left text-center">
                  {props.mainHeading}
                </h1>
                <h1 className="text-titleSmallMobile sm:text-titleSmallTablet lg:text-titleSmall text-blue-aqua uppercase font-universBoldCondensed mt-4 md:mt-0">
                  {props.secondHeading}
                </h1>
                <p className="text-white font-universLightCondensed my-6 text-base text-center md:text-left">
                  {props.tagline}
                </p>
                {props.cta && props.cta.title && (
                  <Cta
                    {...props.cta}
                    classes="inline-block overflow-hidden mt-6 py-3 px-6 leading-none font-universBoldCondensed rounded shadow transition-colors duration-150 bg-white focus:shadow-outline hover:bg-blue-aqua hover:text-white"
                  />
                )}
              </div>
            </div>
            <div className="w-full md:w-1/3">{heroImageComponent}</div>
          </div>
        </FadeInContainer>
      </SectionContainer>
    </MaxWidthContainer>
  );
};

export default Hero;
